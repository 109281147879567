<template lang="pug">
modal(size='lg' mode='drawer' class='[&_.modal__content]:h-full [&_.tabs__details]:flex-1' @close='$emit("close")')
  modal-header(icon='custom-import-to-project')
    span {{ $t('Import Products') }}
    template(#below)
      notification(v-if='inquiryId' type='warning' class='mb-4') {{ $t('Produkte aus Excel oder CSV-Dateien werden aktuell nur in das Projekt aber noch nicht direkt in die Anfrage importiert.') }}
  template(v-if='!selectedFile')
    p(class='prose mb-6 max-w-full text-sm text-text-light' v-html='$t("Wähle hier deinen eigenen Produktlisten aus und füge sie dem aktuell ausgewählten Projekt oder Anfrage hinzu.")')
    control-file#data(accept='.xlsx, .xls, .csv, .obx' centered class='mb-6 h-[50vh]' :info='$t("XLS, XLSX, CSV oder OBX (PCON)")' @update:modelValue='onChange')
    action-bar
      template(#left)
        btn(tertiary @click='$emit("close")') {{ $t('close') }}
  products-import-from-obx-pane(
    v-else-if='selectedFile.name.includes(".obx")'
    :projectId='projectId'
    :file='selectedFile'
    :inquiryId='inquiryId'
    :tag='tag'
    @reset='selectedFile = null'
    @close='$emit("close")'
  )
  products-import-from-csv-or-xls-pane(v-else :newFile='newFile' :file='selectedFile' :projectId='projectId' :inquiryId='inquiryId' @reset='selectedFile = null' @close='$emit("close")')
</template>

<script setup lang="ts">
import { useGlobalFileState } from '@/composables/'
import { ref } from 'vue'

defineProps({
  projectId: { type: String, required: true },
  inquiryId: { type: String },
  tag: { type: String },
})
defineOptions({ inheritAttrs: false })

const { file: selectedFile } = useGlobalFileState()

const newFile = ref(false)
const onChange = (files: File[]) => {
  if (files.length) {
    newFile.value = true
    selectedFile.value = files[0]
  }
}
</script>
